import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Router, Navigate } from 'react-router-dom';
import Login from './pages/Login/login';
import Contracts from './pages/Contracts/contracts';

function checkLoggedIn() {
  if (localStorage.getItem('basicAuth') === null) {
    console.log('logged out');
    return false;
  } else {
    console.log('logged in');
    return true;
  }
}

function App() {
  return (
    <div>
      
        <Routes>
            <Route exact path='/' element={<Login />} />
            {/* <Route exact path='/' element={<Contracts />} /> */}

            <Route
                path="/contracts"
                element={
                    <RequireAuth redirectTo="/">
                        <Contracts/>
                    </RequireAuth>}
            />

            <Route path={"*"} element={ <Navigate replace to={ "/" }/> }/>
        </Routes>
    
    </div>
  );
}

function RequireAuth({children, redirectTo}) {
  let isAuthenticated = checkLoggedIn();
  return isAuthenticated ? children : <Navigate to={redirectTo}/>;
}

export default App;
