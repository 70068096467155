import React, { useState, useEffect, useRef } from 'react';
import { AuthedNavbar, Currency, Finalised, Frame, FrameWrapper, Options, PropertyDefault, PropertyDefaultWrapper, PropertyWrapper, Sort, Swap} from "../../components";
import { Back, Icon115, Icon116, Icon183, Icon7, Icon47 } from "../../icons";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { CategoryExit1 } from "../../icons";
import { Grid, Row, Col, Input, Table, Button, Form, ButtonGroup, Drawer, Nav, Notification, useToaster } from 'rsuite';
import GlobalStyle from '../../globalStyles';

const { Column, HeaderCell, Cell } = Table;

const Contract = () => {

  const navigate = useNavigate();

  const formRef = useRef(null);

  const [active, setActive] = React.useState('Contracts');
  const [contracts, setContracts] = React.useState([])
  const [expand, setExpand] = React.useState(true);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [tableWidth, setTableWidth] = useState(0)

  let baseURL = window.location.origin;

  useEffect(() => {
    getcontracts();
  },[]);

  let autoResize = () => {
    setTableWidth(window.innerWidth-200);
    // setInitializeWidth(Math.max(window.innerWidth-200-1400,150));
  }

  useEffect(() => {
      window.addEventListener('resize', autoResize)
      autoResize();
  }, [])

  async function getcontracts(){
    console.log(baseURL+`/api/getcontracts`)
    const response = await fetch(baseURL+`/api/getcontracts`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log(results.availablecontracts)
    setContracts(results.availablecontracts)
  }

  async function removecontract(name){
    console.log(baseURL+`/api/removecontract`)
    await fetch(baseURL+`/api/removecontract`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify({contractName: name})
    })

    getcontracts()
  }

  const Heading = ({selection}) => {
    return (
      <h4 style={GlobalStyle.pageTitleStyle}>{selection}</h4>
    )
  }  

  const logout = () => {
    localStorage.removeItem('basicAuth');
    navigate("/");
  }

  const handleUpload = async () => {
    console.log('Uploaded files:', fileList);
    
    try {
      const formData = new FormData();
      formData.append('file', fileList);

      const response = await fetch(baseURL+`/api/savecontract`, {
        method: 'POST',
        headers: {'Authorization': localStorage.getItem('basicAuth')},
        body: formData
      });

      formRef.current.value = "";
      formRef.current.type = "text";
      formRef.current.type = "file";

      setFileList([]);
      setFileName('');
      getcontracts()
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const renderDeleteButton = (rowData) => {
    return (
      <Button className="deleteButton" placement="left" size="xs" onClick={()=>{removecontract(rowData.contractName)}}>
        Delete
      </Button>
    );
  };

  const CompactCell = (props) => {
    return <Cell {...props} style={{ padding: 5 , paddingTop: 10}} />;
  };

  const setFile = (file) => {
    setFileList(file)
    setFileName(file.name)
  }

  return (
    <div className="create">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="frame-2">
            <AuthedNavbar active={active} expand={expand} onSelect={setActive} expandCallBack={() => setExpand(!expand)} logoutCallBack={()=> logout()} />
            <div className="frame-3">
              <header className="header">
                <div className="frame-4">
                </div>
              </header>
              <div className="frame-5">
                <div className="frame-6">
                  <Grid className="submitGrid">
                    <Row>
                      <Col md={1} sm={0} xs={0}/>
                      <Col md={22} sm={24} xs={24}>
                        <div className="transactions-table">
                          <Form onSubmit={handleUpload}>
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                id="file"
                                ref={formRef}
                                className="file-input"
                                onChange={(e) => setFile(e.target.files[0])}
                              />

                              <label htmlFor="file" className="file-label">
                                Choose Contract File
                              </label>

                              <div className="file-name">{fileName}</div>
                            </div>
                            {fileName != ''&& <Button className="submitButton" type="submit">Upload Contract File</Button>}
                          </Form>
                        </div>
                        <div className="mini-potfolio-card-2">
                          <div className="frame-23">
                          <Table virtualized
                            height={500}
                            width={'100%'}
                            hover={false}
                            data={contracts}
                            >
                            
                            <Column
                            flexGrow={2}
                            fullText>
                            <HeaderCell>Contract Name</HeaderCell>
                            <CompactCell dataKey="contractName" />
                            </Column>
                            
                            <Column
                              flexGrow={3}
                              fullText>
                              <HeaderCell>Contract Description</HeaderCell>
                              <CompactCell dataKey="description" />
                            </Column>
                     
                            <Column
                              flexGrow={1}
                              verticalAlign="middle"
                              fullText>
                              <HeaderCell>Delete</HeaderCell>
                              <Cell>{renderDeleteButton}</Cell>
                            </Column>

                          </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contract;