/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChartBar11 = ({ className }) => {
  return (
    <svg
      className={`chart-bar-11 ${className}`}
      fill="none"
      height="136"
      viewBox="0 0 32 136"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M-0.00012207 6C-0.00012207 2.68629 2.68617 0 5.99988 0H25.9999C29.3136 0 31.9999 2.68629 31.9999 6V136H-0.00012207V6Z"
        fill="#D6BBFB"
      />
      <path
        className="path"
        d="M-0.00012207 68.3332C-0.00012207 65.0195 2.68617 62.3333 5.99988 62.3333H25.9999C29.3136 62.3333 31.9999 65.0195 31.9999 68.3333V136H-0.00012207V68.3332Z"
        fill="#9E77ED"
      />
    </svg>
  );
};
